import { connect } from 'react-redux';
import { articleActions } from '@ducks/article';
import Article from './Article';

const mapStateToProps = (state, ownProps) => {
  const currentArticle = state.article.items.find(item =>
    ownProps.id.includes(item.id)
  );
  const { salesposters } = state.article;
  const articleData = { ...currentArticle, id: ownProps.id } || {};
  const userData = {
    isLoggedIn: state.user.isLoggedIn,
    hasSubscription: state.user.hasSubscription
  };
  const productData = {
    product: state.config.product
  };
  const requestStatus = {
    requestStatus: state.article.requestStatus,
    error: state.article.error
  };
  return {
    ...articleData,
    ...userData,
    ...productData,
    ...requestStatus,
    salesposters
  };
};

const mapDispatchToProps = dispatch => ({
  getArticle: id => {
    dispatch(articleActions.getArticle(id));
  },
  getNonMemberArticle: id => {
    dispatch(articleActions.getNonMemberArticle(id));
  },
  setCurrentArticle: id => {
    dispatch(articleActions.setCurrentArticle(id));
  }
});

const articleContainer = connect(mapStateToProps, mapDispatchToProps)(Article);

export default articleContainer;
